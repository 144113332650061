var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Layout',[_c('PageHeader',{attrs:{"title":_vm.title,"items":_vm.items}}),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-12"},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-body"},[(_vm.notification.message)?_c('b-alert',{staticClass:"mb-3",attrs:{"variant":_vm.notification.type,"dismissible":"","show":""},on:{"dismissed":function($event){return _vm.$store.dispatch('notification/clear')}}},[_vm._v(_vm._s(_vm.notification.message))]):_vm._e(),_c('form',{staticClass:"needs-validation",on:{"submit":function($event){$event.preventDefault();return _vm.formSubmit($event)}}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[_c('div',{staticClass:"form-group mt-3"},[_c('label',{attrs:{"for":"clientId"}},[_vm._v("Client")]),_c('b-form-select',{staticClass:"form-control",class:{
                      'is-invalid': _vm.errors['clientId'],
                    },attrs:{"options":_vm.clients},scopedSlots:_vm._u([{key:"first",fn:function(){return [_c('option',{attrs:{"disabled":""},domProps:{"value":''}},[_vm._v("Pilih Client")])]},proxy:true},{key:"option",fn:function(ref){
                    var option = ref.option;
return [_c('span',[_vm._v(_vm._s(option.name))])]}}]),model:{value:(_vm.form.clientId),callback:function ($$v) {_vm.$set(_vm.form, "clientId", $$v)},expression:"form.clientId"}})],1)]),_c('div',{staticClass:"col-md-12"},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"programName"}},[_vm._v("Nama")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.programName),expression:"form.programName"}],staticClass:"form-control",class:{
                      'is-invalid': _vm.errors['programName'],
                    },attrs:{"id":"programName","type":"text","placeholder":"Nama"},domProps:{"value":(_vm.form.programName)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "programName", $event.target.value)}}}),(_vm.errors['programName'])?_c('div',{staticClass:"invalid-feedback"},[_c('span',[_vm._v(_vm._s(_vm.errors["programName"]))])]):_vm._e()]),_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"name"}},[_vm._v("Type")]),_c('b-form-select',{staticClass:"form-control",class:{
                      'is-invalid': _vm.errors['type'],
                    },attrs:{"options":_vm.types},scopedSlots:_vm._u([{key:"first",fn:function(){return [_c('option',{attrs:{"disabled":""},domProps:{"value":''}},[_vm._v("Pilih Type")])]},proxy:true},{key:"option",fn:function(ref){
                    var option = ref.option;
return [_c('span',[_vm._v(_vm._s(option))])]}}]),model:{value:(_vm.form.type),callback:function ($$v) {_vm.$set(_vm.form, "type", $$v)},expression:"form.type"}}),(_vm.errors['type'])?_c('div',{staticClass:"invalid-feedback"},[_c('span',[_vm._v(_vm._s(_vm.errors["type"]))])]):_vm._e()],1)]),_c('div',{staticClass:"col-md-12"},[_c('div',{staticClass:"form-group mt-3"},[_c('label',{attrs:{"for":"banner"}},[_vm._v("Banner")]),_c('input',{staticClass:"form-control",class:{
                      'is-invalid': _vm.errors['banner'],
                    },attrs:{"id":"banner","type":"file","placeholder":"Banner"},on:{"change":_vm.uploadImage}}),_c('img',{staticClass:"mt-3",attrs:{"src":_vm._f("media")(_vm.form.banner),"width":"300","alt":""}}),(_vm.errors['banner'])?_c('div',{staticClass:"invalid-feedback"},[_c('span',[_vm._v(_vm._s(_vm.errors["banner"]))])]):_vm._e()])]),_c('div',{staticClass:"col-md-12"},[_c('div',{staticClass:"form-group mt-3"},[_c('label',{attrs:{"for":"description"}},[_vm._v("Deskripsi")]),_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.description),expression:"form.description"}],staticClass:"form-control",class:{
                      'is-invalid': _vm.errors['description'],
                    },attrs:{"id":"description","placeholder":"Deskripsi"},domProps:{"value":(_vm.form.description)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "description", $event.target.value)}}}),(_vm.errors['description'])?_c('div',{staticClass:"invalid-feedback"},[_c('span',[_vm._v(_vm._s(_vm.errors["description"]))])]):_vm._e()])]),_c('div',{staticClass:"col-md-12"},[_c('div',{staticClass:"form-group mt-3"},[_c('label',{attrs:{"for":"listOfActivities"}},[_vm._v("Daftar Aktivitas")]),_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.listOfActivities),expression:"form.listOfActivities"}],staticClass:"form-control",class:{
                      'is-invalid': _vm.errors['listOfActivities'],
                    },attrs:{"id":"listOfActivities","placeholder":"List of Activities"},domProps:{"value":(_vm.form.listOfActivities)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "listOfActivities", $event.target.value)}}}),(_vm.errors['listOfActivities'])?_c('div',{staticClass:"invalid-feedback"},[_c('span',[_vm._v(_vm._s(_vm.errors["listOfActivities"]))])]):_vm._e()])]),_c('div',{staticClass:"col-md-12"},[_c('div',{staticClass:"form-group mt-3"},[_c('label',{attrs:{"for":"eventDate"}},[_vm._v("Tanggal Event")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.eventDate),expression:"form.eventDate"}],staticClass:"form-control",class:{
                      'is-invalid': _vm.errors['eventDate'],
                    },attrs:{"id":"eventDate","type":"date","placeholder":"Event Date"},domProps:{"value":(_vm.form.eventDate)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "eventDate", $event.target.value)}}}),(_vm.errors['eventDate'])?_c('div',{staticClass:"invalid-feedback"},[_c('span',[_vm._v(_vm._s(_vm.errors["eventDate"]))])]):_vm._e()])]),_c('div',{staticClass:"col-md-12"},[_c('div',{staticClass:"form-group mt-3"},[_c('label',{attrs:{"for":"location"}},[_vm._v("Lokasi")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.location),expression:"form.location"}],staticClass:"form-control",class:{
                      'is-invalid': _vm.errors['location'],
                    },attrs:{"id":"location","type":"text","placeholder":"Location"},domProps:{"value":(_vm.form.location)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "location", $event.target.value)}}}),(_vm.errors['location'])?_c('div',{staticClass:"invalid-feedback"},[_c('span',[_vm._v(_vm._s(_vm.errors["location"]))])]):_vm._e()])]),_c('div',{staticClass:"col-md-12"},[_c('div',{staticClass:"form-group mt-3"},[_c('label',{attrs:{"for":"video"}},[_vm._v("Video")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.video),expression:"form.video"}],staticClass:"form-control",class:{
                      'is-invalid': _vm.errors['video'],
                    },attrs:{"id":"video","type":"text","placeholder":"Video"},domProps:{"value":(_vm.form.video)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "video", $event.target.value)}}}),(_vm.errors['video'])?_c('div',{staticClass:"invalid-feedback"},[_c('span',[_vm._v(_vm._s(_vm.errors["video"]))])]):_vm._e()])]),_c('div',{staticClass:"col-md-12"},[_c('label',{attrs:{"for":"galleries"}},[_vm._v("Galeri")]),_c('table',{staticClass:"table table-bordered"},[_c('thead',[_c('tr',[_c('th',[_vm._v("Gambar")]),_c('th',[_vm._v("Deskripsi")])])]),_c('tbody',[_vm._l((_vm.form.galleries),function(highlight,index){return _c('tr',{key:index},[_c('td',[_c('input',{staticClass:"form-control",class:{
                            'is-invalid': _vm.errors['image'],
                          },attrs:{"type":"file","placeholder":"image"},on:{"change":function($event){return _vm.uploadImage($event, index)}}}),_c('img',{staticClass:"mb-3",attrs:{"src":_vm._f("media")(highlight.imageUrl),"width":"300","alt":""}})]),_c('td',[_c('input',{directives:[{name:"model",rawName:"v-model",value:(highlight.description),expression:"highlight.description"}],staticClass:"form-control",class:{
                            'is-invalid': _vm.errors['description'],
                          },attrs:{"type":"text","placeholder":"Deskripsi"},domProps:{"value":(highlight.description)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(highlight, "description", $event.target.value)}}}),(_vm.errors['description'])?_c('div',{staticClass:"invalid-feedback"},[_c('span',[_vm._v(_vm._s(_vm.errors["description"]))])]):_vm._e()]),_c('td',{staticClass:"text-center"},[_c('button',{staticClass:"btn btn-danger",on:{"click":function($event){return _vm.form.galleries.splice(index, 1)}}},[_vm._v(" Hapus ")])])])}),_c('tr',[_c('td',{attrs:{"colspan":"2"}},[_c('button',{staticClass:"btn btn-primary",attrs:{"type":"button"},on:{"click":function($event){return _vm.form.galleries.push({
                              imageUrl: '',
                              description: '',
                            })}}},[_vm._v(" Tambah ")])])])],2)])])]),_c('button',{staticClass:"btn btn-primary mt-4 form-control",attrs:{"disabled":_vm.loading,"type":"submit"}},[(!_vm.loading)?_c('span',[_vm._v(_vm._s(_vm.title))]):_c('div',{staticClass:"spinner-border text-light spinner-border-sm",attrs:{"role":"status"}},[_c('span',{staticClass:"sr-only"},[_vm._v("Loading...")])])])])],1)])])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }